var exports = {};

/* jstz.min.js Version: 1.0.6 Build date: 2019-09-09 */
!function (e) {
  var a = function () {
    "use strict";

    var e = "s",
        s = {
      DAY: 86400000,
      HOUR: 3600000,
      MINUTE: 60000,
      SECOND: 1000,
      BASELINE_YEAR: 2014,
      MAX_SCORE: 864000000,
      AMBIGUITIES: {
        "America/Denver": ["America/Mazatlan"],
        "America/Chicago": ["America/Mexico_City"],
        "America/Asuncion": ["America/Campo_Grande", "America/Santiago"],
        "America/Montevideo": ["America/Sao_Paulo", "America/Santiago"],
        "Asia/Beirut": ["Asia/Amman", "Asia/Jerusalem", "Europe/Helsinki", "Asia/Damascus", "Africa/Cairo", "Asia/Gaza", "Europe/Minsk", "Africa/Windhoek"],
        "Pacific/Auckland": ["Pacific/Fiji"],
        "America/Los_Angeles": ["America/Santa_Isabel"],
        "America/New_York": ["America/Havana"],
        "America/Halifax": ["America/Goose_Bay"],
        "America/Godthab": ["America/Miquelon"],
        "Asia/Dubai": ["Asia/Yerevan"],
        "Asia/Jakarta": ["Asia/Krasnoyarsk"],
        "Asia/Shanghai": ["Asia/Irkutsk", "Australia/Perth"],
        "Australia/Sydney": ["Australia/Lord_Howe"],
        "Asia/Tokyo": ["Asia/Yakutsk"],
        "Asia/Dhaka": ["Asia/Omsk"],
        "Asia/Baku": ["Asia/Yerevan"],
        "Australia/Brisbane": ["Asia/Vladivostok"],
        "Pacific/Noumea": ["Asia/Vladivostok"],
        "Pacific/Majuro": ["Asia/Kamchatka", "Pacific/Fiji"],
        "Pacific/Tongatapu": ["Pacific/Apia"],
        "Asia/Baghdad": ["Europe/Minsk", "Europe/Moscow"],
        "Asia/Karachi": ["Asia/Yekaterinburg"],
        "Africa/Johannesburg": ["Asia/Gaza", "Africa/Cairo"]
      }
    },
        i = function (e) {
      var a = -e.getTimezoneOffset();
      return null !== a ? a : 0;
    },
        r = function () {
      for (var e = [], a = 0; a <= 11; a++) for (var r = 1; r <= 28; r++) {
        var n = i(new Date(s.BASELINE_YEAR, a, r));
        e ? e && e[e.length - 1] !== n && e.push(n) : e.push();
      }

      return e;
    },
        n = function () {
      var a = 0,
          s = r();
      return s.length > 1 && (a = s[0] - s[1]), s.length > 3 ? s[0] + ",1,weird" : a < 0 ? s[0] + ",1" : a > 0 ? s[1] + ",1," + e : s[0] + ",0";
    },
        o = function () {
      var e, a;
      if (Intl && "undefined" != typeof Intl && "undefined" != typeof Intl.DateTimeFormat && (e = Intl.DateTimeFormat(), "undefined" != typeof e && "undefined" != typeof e.resolvedOptions)) return a = e.resolvedOptions().timeZone, a && (a.indexOf("/") > -1 || "UTC" === a) ? a : void 0;
    },
        t = function (e) {
      for (var a = new Date(e, 0, 1, 0, 0, 1, 0).getTime(), s = new Date(e, 12, 31, 23, 59, 59).getTime(), i = a, r = new Date(i).getTimezoneOffset(), n = null, o = null; i < s - 86400000;) {
        var t = new Date(i),
            A = t.getTimezoneOffset();
        A !== r && (A < r && (n = t), A > r && (o = t), r = A), i += 86400000;
      }

      return !(!n || !o) && {
        s: u(n).getTime(),
        e: u(o).getTime()
      };
    },
        u = function f(e, a, i) {
      "undefined" == typeof a && (a = s.DAY, i = s.HOUR);

      for (var r = new Date(e.getTime() - a).getTime(), n = e.getTime() + a, o = new Date(r).getTimezoneOffset(), t = r, u = null; t < n - i;) {
        var A = new Date(t),
            c = A.getTimezoneOffset();

        if (c !== o) {
          u = A;
          break;
        }

        t += i;
      }

      return a === s.DAY ? f(u, s.HOUR, s.MINUTE) : a === s.HOUR ? f(u, s.MINUTE, s.SECOND) : u;
    },
        A = function (e, a, s, i) {
      if ("N/A" !== s) return s;

      if ("Asia/Beirut" === a) {
        if ("Africa/Cairo" === i.name && 1398376800000 === e[6].s && 1411678800000 === e[6].e) return 0;
        if ("Asia/Jerusalem" === i.name && 1395964800000 === e[6].s && 1411858800000 === e[6].e) return 0;
      } else if ("America/Santiago" === a) {
        if ("America/Asuncion" === i.name && 1412481600000 === e[6].s && 1397358000000 === e[6].e) return 0;
        if ("America/Campo_Grande" === i.name && 1413691200000 === e[6].s && 1392519600000 === e[6].e) return 0;
      } else if ("America/Montevideo" === a) {
        if ("America/Sao_Paulo" === i.name && 1413687600000 === e[6].s && 1392516000000 === e[6].e) return 0;
      } else if ("Pacific/Auckland" === a && "Pacific/Fiji" === i.name && 1414245600000 === e[6].s && 1396101600000 === e[6].e) return 0;

      return s;
    },
        c = function (e, i) {
      for (var r = function (a) {
        for (var r = 0, n = 0; n < e.length; n++) if (a.rules[n] && e[n]) {
          if (!(e[n].s >= a.rules[n].s && e[n].e <= a.rules[n].e)) {
            r = "N/A";
            break;
          }

          if (r = 0, r += Math.abs(e[n].s - a.rules[n].s), r += Math.abs(a.rules[n].e - e[n].e), r > s.MAX_SCORE) {
            r = "N/A";
            break;
          }
        }

        return r = A(e, i, r, a);
      }, n = {}, o = a.olson.dst_rules.zones, t = o.length, u = s.AMBIGUITIES[i], c = 0; c < t; c++) {
        var m = o[c],
            l = r(o[c]);
        "N/A" !== l && (n[m.name] = l);
      }

      for (var f in n) if (n.hasOwnProperty(f)) for (var d = 0; d < u.length; d++) if (u[d] === f) return f;

      return i;
    },
        m = function (e) {
      var s = function () {
        for (var e = [], s = 0; s < a.olson.dst_rules.years.length; s++) {
          var i = t(a.olson.dst_rules.years[s]);
          e.push(i);
        }

        return e;
      },
          i = function (e) {
        for (var a = 0; a < e.length; a++) if (e[a] !== !1) return !0;

        return !1;
      },
          r = s(),
          n = i(r);

      return n ? c(r, e) : e;
    },
        l = function (e) {
      var i = !1,
          t = n();
      return (e || "undefined" == typeof e) && (i = o()), i || (i = a.olson.timezones[t], "undefined" != typeof s.AMBIGUITIES[i] && (i = m(i))), {
        name: function () {
          return i;
        },
        using_intl: e || "undefined" == typeof e,
        needle: t,
        offsets: r()
      };
    };

    return {
      determine: l
    };
  }();

  a.olson = a.olson || {}, a.olson.timezones = {
    "-720,0": "Etc/GMT+12",
    "-660,0": "Pacific/Pago_Pago",
    "-660,1,s": "Pacific/Apia",
    "-600,1": "America/Adak",
    "-600,0": "Pacific/Honolulu",
    "-570,0": "Pacific/Marquesas",
    "-540,0": "Pacific/Gambier",
    "-540,1": "America/Anchorage",
    "-480,1": "America/Los_Angeles",
    "-480,0": "Pacific/Pitcairn",
    "-420,0": "America/Phoenix",
    "-420,1": "America/Denver",
    "-360,0": "America/Guatemala",
    "-360,1": "America/Chicago",
    "-360,1,s": "Pacific/Easter",
    "-300,0": "America/Bogota",
    "-300,1": "America/New_York",
    "-270,0": "America/Caracas",
    "-240,1": "America/Halifax",
    "-240,0": "America/Santo_Domingo",
    "-240,1,s": "America/Asuncion",
    "-210,1": "America/St_Johns",
    "-180,1": "America/Godthab",
    "-180,0": "America/Buenos_Aires",
    "-180,1,s": "America/Montevideo",
    "-120,0": "America/Noronha",
    "-120,1": "America/Noronha",
    "-60,1": "Atlantic/Azores",
    "-60,0": "Atlantic/Cape_Verde",
    "0,0": "UTC",
    "0,1": "Europe/London",
    "0,1,weird": "Africa/Casablanca",
    "60,1": "Europe/Berlin",
    "60,0": "Africa/Lagos",
    "60,1,weird": "Africa/Casablanca",
    "120,1": "Asia/Beirut",
    "120,1,weird": "Africa/Cairo",
    "120,0": "Africa/Johannesburg",
    "180,0": "Asia/Baghdad",
    "180,1": "Europe/Moscow",
    "210,1": "Asia/Tehran",
    "240,0": "Asia/Dubai",
    "240,1": "Asia/Baku",
    "270,0": "Asia/Kabul",
    "300,1": "Asia/Yekaterinburg",
    "300,0": "Asia/Karachi",
    "330,0": "Asia/Calcutta",
    "345,0": "Asia/Katmandu",
    "360,0": "Asia/Dhaka",
    "360,1": "Asia/Omsk",
    "390,0": "Asia/Rangoon",
    "420,1": "Asia/Krasnoyarsk",
    "420,0": "Asia/Jakarta",
    "480,0": "Asia/Shanghai",
    "480,1": "Asia/Irkutsk",
    "525,0": "Australia/Eucla",
    "525,1,s": "Australia/Eucla",
    "540,1": "Asia/Yakutsk",
    "540,0": "Asia/Tokyo",
    "570,0": "Australia/Darwin",
    "570,1,s": "Australia/Adelaide",
    "600,0": "Australia/Brisbane",
    "600,1": "Asia/Vladivostok",
    "600,1,s": "Australia/Sydney",
    "630,1,s": "Australia/Lord_Howe",
    "660,1": "Asia/Kamchatka",
    "660,0": "Pacific/Noumea",
    "690,0": "Pacific/Norfolk",
    "720,1,s": "Pacific/Auckland",
    "720,0": "Pacific/Majuro",
    "765,1,s": "Pacific/Chatham",
    "780,0": "Pacific/Tongatapu",
    "780,1,s": "Pacific/Apia",
    "840,0": "Pacific/Kiritimati"
  }, a.olson.dst_rules = {
    years: [2008, 2009, 2010, 2011, 2012, 2013, 2014],
    zones: [{
      name: "Africa/Cairo",
      rules: [{
        e: 1219957200000,
        s: 1209074400000
      }, {
        e: 1250802000000,
        s: 1240524000000
      }, {
        e: 1285880400000,
        s: 1284069600000
      }, !1, !1, !1, {
        e: 1411678800000,
        s: 1406844000000
      }]
    }, {
      name: "America/Asuncion",
      rules: [{
        e: 1205031600000,
        s: 1224388800000
      }, {
        e: 1236481200000,
        s: 1255838400000
      }, {
        e: 1270954800000,
        s: 1286078400000
      }, {
        e: 1302404400000,
        s: 1317528000000
      }, {
        e: 1333854000000,
        s: 1349582400000
      }, {
        e: 1364094000000,
        s: 1381032000000
      }, {
        e: 1395543600000,
        s: 1412481600000
      }]
    }, {
      name: "America/Campo_Grande",
      rules: [{
        e: 1203217200000,
        s: 1224388800000
      }, {
        e: 1234666800000,
        s: 1255838400000
      }, {
        e: 1266721200000,
        s: 1287288000000
      }, {
        e: 1298170800000,
        s: 1318737600000
      }, {
        e: 1330225200000,
        s: 1350792000000
      }, {
        e: 1361070000000,
        s: 1382241600000
      }, {
        e: 1392519600000,
        s: 1413691200000
      }]
    }, {
      name: "America/Goose_Bay",
      rules: [{
        e: 1225594860000,
        s: 1205035260000
      }, {
        e: 1257044460000,
        s: 1236484860000
      }, {
        e: 1289098860000,
        s: 1268539260000
      }, {
        e: 1320555600000,
        s: 1299988860000
      }, {
        e: 1352005200000,
        s: 1331445600000
      }, {
        e: 1383454800000,
        s: 1362895200000
      }, {
        e: 1414904400000,
        s: 1394344800000
      }]
    }, {
      name: "America/Havana",
      rules: [{
        e: 1224997200000,
        s: 1205643600000
      }, {
        e: 1256446800000,
        s: 1236488400000
      }, {
        e: 1288501200000,
        s: 1268542800000
      }, {
        e: 1321160400000,
        s: 1300597200000
      }, {
        e: 1352005200000,
        s: 1333256400000
      }, {
        e: 1383454800000,
        s: 1362891600000
      }, {
        e: 1414904400000,
        s: 1394341200000
      }]
    }, {
      name: "America/Mazatlan",
      rules: [{
        e: 1225008000000,
        s: 1207472400000
      }, {
        e: 1256457600000,
        s: 1238922000000
      }, {
        e: 1288512000000,
        s: 1270371600000
      }, {
        e: 1319961600000,
        s: 1301821200000
      }, {
        e: 1351411200000,
        s: 1333270800000
      }, {
        e: 1382860800000,
        s: 1365325200000
      }, {
        e: 1414310400000,
        s: 1396774800000
      }]
    }, {
      name: "America/Mexico_City",
      rules: [{
        e: 1225004400000,
        s: 1207468800000
      }, {
        e: 1256454000000,
        s: 1238918400000
      }, {
        e: 1288508400000,
        s: 1270368000000
      }, {
        e: 1319958000000,
        s: 1301817600000
      }, {
        e: 1351407600000,
        s: 1333267200000
      }, {
        e: 1382857200000,
        s: 1365321600000
      }, {
        e: 1414306800000,
        s: 1396771200000
      }]
    }, {
      name: "America/Miquelon",
      rules: [{
        e: 1225598400000,
        s: 1205038800000
      }, {
        e: 1257048000000,
        s: 1236488400000
      }, {
        e: 1289102400000,
        s: 1268542800000
      }, {
        e: 1320552000000,
        s: 1299992400000
      }, {
        e: 1352001600000,
        s: 1331442000000
      }, {
        e: 1383451200000,
        s: 1362891600000
      }, {
        e: 1414900800000,
        s: 1394341200000
      }]
    }, {
      name: "America/Santa_Isabel",
      rules: [{
        e: 1225011600000,
        s: 1207476000000
      }, {
        e: 1256461200000,
        s: 1238925600000
      }, {
        e: 1289120400000,
        s: 1268560800000
      }, {
        e: 1320570000000,
        s: 1300010400000
      }, {
        e: 1352019600000,
        s: 1331460000000
      }, {
        e: 1383469200000,
        s: 1362909600000
      }, {
        e: 1414918800000,
        s: 1394359200000
      }]
    }, {
      name: "America/Santiago",
      rules: [{
        e: 1206846000000,
        s: 1223784000000
      }, {
        e: 1237086000000,
        s: 1255233600000
      }, {
        e: 1270350000000,
        s: 1286683200000
      }, {
        e: 1304823600000,
        s: 1313899200000
      }, {
        e: 1335668400000,
        s: 1346558400000
      }, {
        e: 1367118000000,
        s: 1378612800000
      }, {
        e: 1398567600000,
        s: 1410062400000
      }]
    }, {
      name: "America/Sao_Paulo",
      rules: [{
        e: 1203213600000,
        s: 1224385200000
      }, {
        e: 1234663200000,
        s: 1255834800000
      }, {
        e: 1266717600000,
        s: 1287284400000
      }, {
        e: 1298167200000,
        s: 1318734000000
      }, {
        e: 1330221600000,
        s: 1350788400000
      }, {
        e: 1361066400000,
        s: 1382238000000
      }, {
        e: 1392516000000,
        s: 1413687600000
      }]
    }, {
      name: "Asia/Amman",
      rules: [{
        e: 1225404000000,
        s: 1206655200000
      }, {
        e: 1256853600000,
        s: 1238104800000
      }, {
        e: 1288303200000,
        s: 1269554400000
      }, {
        e: 1319752800000,
        s: 1301608800000
      }, !1, !1, {
        e: 1414706400000,
        s: 1395957600000
      }]
    }, {
      name: "Asia/Damascus",
      rules: [{
        e: 1225486800000,
        s: 1207260000000
      }, {
        e: 1256850000000,
        s: 1238104800000
      }, {
        e: 1288299600000,
        s: 1270159200000
      }, {
        e: 1319749200000,
        s: 1301608800000
      }, {
        e: 1351198800000,
        s: 1333058400000
      }, {
        e: 1382648400000,
        s: 1364508000000
      }, {
        e: 1414702800000,
        s: 1395957600000
      }]
    }, {
      name: "Asia/Dubai",
      rules: [!1, !1, !1, !1, !1, !1, !1]
    }, {
      name: "Asia/Gaza",
      rules: [{
        e: 1219957200000,
        s: 1206655200000
      }, {
        e: 1252015200000,
        s: 1238104800000
      }, {
        e: 1281474000000,
        s: 1269640860000
      }, {
        e: 1312146000000,
        s: 1301608860000
      }, {
        e: 1348178400000,
        s: 1333058400000
      }, {
        e: 1380229200000,
        s: 1364508000000
      }, {
        e: 1414098000000,
        s: 1395957600000
      }]
    }, {
      name: "Asia/Irkutsk",
      rules: [{
        e: 1224957600000,
        s: 1206813600000
      }, {
        e: 1256407200000,
        s: 1238263200000
      }, {
        e: 1288461600000,
        s: 1269712800000
      }, !1, !1, !1, !1]
    }, {
      name: "Asia/Jerusalem",
      rules: [{
        e: 1223161200000,
        s: 1206662400000
      }, {
        e: 1254006000000,
        s: 1238112000000
      }, {
        e: 1284246000000,
        s: 1269561600000
      }, {
        e: 1317510000000,
        s: 1301616000000
      }, {
        e: 1348354800000,
        s: 1333065600000
      }, {
        e: 1382828400000,
        s: 1364515200000
      }, {
        e: 1414278000000,
        s: 1395964800000
      }]
    }, {
      name: "Asia/Kamchatka",
      rules: [{
        e: 1224943200000,
        s: 1206799200000
      }, {
        e: 1256392800000,
        s: 1238248800000
      }, {
        e: 1288450800000,
        s: 1269698400000
      }, !1, !1, !1, !1]
    }, {
      name: "Asia/Krasnoyarsk",
      rules: [{
        e: 1224961200000,
        s: 1206817200000
      }, {
        e: 1256410800000,
        s: 1238266800000
      }, {
        e: 1288465200000,
        s: 1269716400000
      }, !1, !1, !1, !1]
    }, {
      name: "Asia/Omsk",
      rules: [{
        e: 1224964800000,
        s: 1206820800000
      }, {
        e: 1256414400000,
        s: 1238270400000
      }, {
        e: 1288468800000,
        s: 1269720000000
      }, !1, !1, !1, !1]
    }, {
      name: "Asia/Vladivostok",
      rules: [{
        e: 1224950400000,
        s: 1206806400000
      }, {
        e: 1256400000000,
        s: 1238256000000
      }, {
        e: 1288454400000,
        s: 1269705600000
      }, !1, !1, !1, !1]
    }, {
      name: "Asia/Yakutsk",
      rules: [{
        e: 1224954000000,
        s: 1206810000000
      }, {
        e: 1256403600000,
        s: 1238259600000
      }, {
        e: 1288458000000,
        s: 1269709200000
      }, !1, !1, !1, !1]
    }, {
      name: "Asia/Yekaterinburg",
      rules: [{
        e: 1224968400000,
        s: 1206824400000
      }, {
        e: 1256418000000,
        s: 1238274000000
      }, {
        e: 1288472400000,
        s: 1269723600000
      }, !1, !1, !1, !1]
    }, {
      name: "Asia/Yerevan",
      rules: [{
        e: 1224972000000,
        s: 1206828000000
      }, {
        e: 1256421600000,
        s: 1238277600000
      }, {
        e: 1288476000000,
        s: 1269727200000
      }, {
        e: 1319925600000,
        s: 1301176800000
      }, !1, !1, !1]
    }, {
      name: "Australia/Lord_Howe",
      rules: [{
        e: 1207407600000,
        s: 1223134200000
      }, {
        e: 1238857200000,
        s: 1254583800000
      }, {
        e: 1270306800000,
        s: 1286033400000
      }, {
        e: 1301756400000,
        s: 1317483000000
      }, {
        e: 1333206000000,
        s: 1349537400000
      }, {
        e: 1365260400000,
        s: 1380987000000
      }, {
        e: 1396710000000,
        s: 1412436600000
      }]
    }, {
      name: "Australia/Perth",
      rules: [{
        e: 1206813600000,
        s: 1224957600000
      }, !1, !1, !1, !1, !1, !1]
    }, {
      name: "Europe/Helsinki",
      rules: [{
        e: 1224982800000,
        s: 1206838800000
      }, {
        e: 1256432400000,
        s: 1238288400000
      }, {
        e: 1288486800000,
        s: 1269738000000
      }, {
        e: 1319936400000,
        s: 1301187600000
      }, {
        e: 1351386000000,
        s: 1332637200000
      }, {
        e: 1382835600000,
        s: 1364691600000
      }, {
        e: 1414285200000,
        s: 1396141200000
      }]
    }, {
      name: "Europe/Minsk",
      rules: [{
        e: 1224979200000,
        s: 1206835200000
      }, {
        e: 1256428800000,
        s: 1238284800000
      }, {
        e: 1288483200000,
        s: 1269734400000
      }, !1, !1, !1, !1]
    }, {
      name: "Europe/Moscow",
      rules: [{
        e: 1224975600000,
        s: 1206831600000
      }, {
        e: 1256425200000,
        s: 1238281200000
      }, {
        e: 1288479600000,
        s: 1269730800000
      }, !1, !1, !1, !1]
    }, {
      name: "Pacific/Apia",
      rules: [!1, !1, !1, {
        e: 1301752800000,
        s: 1316872800000
      }, {
        e: 1333202400000,
        s: 1348927200000
      }, {
        e: 1365256800000,
        s: 1380376800000
      }, {
        e: 1396706400000,
        s: 1411826400000
      }]
    }, {
      name: "Pacific/Fiji",
      rules: [!1, !1, {
        e: 1269698400000,
        s: 1287842400000
      }, {
        e: 1327154400000,
        s: 1319292000000
      }, {
        e: 1358604000000,
        s: 1350741600000
      }, {
        e: 1390050000000,
        s: 1382796000000
      }, {
        e: 1421503200000,
        s: 1414850400000
      }]
    }, {
      name: "Europe/London",
      rules: [{
        e: 1224982800000,
        s: 1206838800000
      }, {
        e: 1256432400000,
        s: 1238288400000
      }, {
        e: 1288486800000,
        s: 1269738000000
      }, {
        e: 1319936400000,
        s: 1301187600000
      }, {
        e: 1351386000000,
        s: 1332637200000
      }, {
        e: 1382835600000,
        s: 1364691600000
      }, {
        e: 1414285200000,
        s: 1396141200000
      }]
    }, {
      name: "Africa/Windhoek",
      rules: [{
        e: 1220749200000,
        s: 1207440000000
      }, {
        e: 1252198800000,
        s: 1238889600000
      }, {
        e: 1283648400000,
        s: 1270339200000
      }, {
        e: 1315098000000,
        s: 1301788800000
      }, {
        e: 1346547600000,
        s: 1333238400000
      }, {
        e: 1377997200000,
        s: 1365292800000
      }, {
        e: 1410051600000,
        s: 1396742400000
      }]
    }]
  }, exports = a;
}();
export default exports;